import React from 'react'
import { createPopper } from '@popperjs/core'
import LeadInfoModal from '../Modals/LeadInfoModal'
import LeadFishyModal from '../Modals/LeadFishyModal'
import LeadDeleteModal from '../Modals/LeadDeleteModal'
import LeadAssignModal from '../Modals/LeadAssignModal'
import LeadClaimEmailModal from '../Modals/LeadClaimEmailModal'
import LeadResendEmailsModal from '../Modals/LeadResendEmailsModal'

const TableDropdown = ({ lead }) => {
  // dropdown props
  const [dropdownPopoverShow, setDropdownPopoverShow] = React.useState(false)
  const [leadInfoModalShow, setLeadInfoModalShow] = React.useState(false)
  const [leadAssignModalShow, setLeadAssignModalShow] = React.useState(false)
  const [leadClaimEmailModalShow, setLeadClaimEmailModalShow] = React.useState(false)
  const [leadDeleteModalShow, setLeadDeleteModalShow] = React.useState(false)
  const [leadResendEmailsModalShow, setLeadResendEmailsModalShow] = React.useState(false)
  const [leadFishyModalShow, setLeadFishyModalShow] = React.useState(false)

  const btnDropdownRef = React.createRef()
  const popoverDropdownRef = React.createRef()

  const openDropdownPopover = () => {
    createPopper(btnDropdownRef.current, popoverDropdownRef.current, {
      placement: 'left-start'
    })
    setDropdownPopoverShow(true)
  }
  const closeDropdownPopover = () => {
    setDropdownPopoverShow(false)
  }

  return (
    <>
      <a
        className='text-blueGray-500 py-1 px-3'
        href='#pablo'
        ref={btnDropdownRef}
        onClick={(e) => {
          e.preventDefault()
          dropdownPopoverShow ? closeDropdownPopover() : openDropdownPopover()
        }}
      >
        <i className='fas fa-ellipsis-v' />
      </a>
      <div
        ref={popoverDropdownRef}
        className={
          (dropdownPopoverShow ? 'block ' : 'hidden ') +
          'bg-white text-base z-50 float-left py-2 list-none text-left rounded shadow-lg min-w-48'
        }
      >
        <a
          href='#pablo'
          className='text-sm py-2 px-4 hover:text-white hover:bg-blue-300 font-normal block w-full whitespace-nowrap bg-transparent text-blueGray-700'
          onClick={(e) => {
            e.preventDefault()
            setLeadInfoModalShow(true)
            setDropdownPopoverShow(false)
          }}
        >
          Lead Info
        </a>
        <a
          href='#pablo'
          className='text-sm py-2 px-4 hover:text-white hover:bg-blue-300 font-normal block w-full whitespace-nowrap bg-transparent text-blueGray-700'
          onClick={(e) => {
            e.preventDefault()
            setLeadAssignModalShow(true)
            setDropdownPopoverShow(false)
          }}
        >
          Assign lead to...
        </a>
        <a
          href='#pablo'
          className='text-sm hover:bg-red-500 hover:text-white py-2 px-4 font-normal block w-full whitespace-nowrap bg-transparent text-blueGray-700'
          onClick={(e) => {
            e.preventDefault()
            setLeadDeleteModalShow(true)
            setDropdownPopoverShow(false)
          }}
        >
          Delete lead
        </a>
        <a
          href='#pablo'
          className='text-sm hover:bg-green-500 hover:text-white py-2 px-4 font-normal block w-full whitespace-nowrap bg-transparent text-blueGray-700'
          onClick={(e) => {
            e.preventDefault()
            setLeadResendEmailsModalShow(true)
            setDropdownPopoverShow(false)
          }}
        >
          Resend lead emails
        </a>
        <a
          href='#pablo'
          className='text-sm py-2 px-4 hover:text-white hover:bg-blue-300 font-normal block w-full whitespace-nowrap bg-transparent text-blueGray-700'
          onClick={(e) => {
            e.preventDefault()
            setLeadFishyModalShow(true)
            setDropdownPopoverShow(false)
          }}
        >
          Send fishy emails...
        </a>
        <a
          href='#pablo'
          className='text-sm py-2 px-4 hover:text-white hover:bg-blue-300 font-normal block w-full whitespace-nowrap bg-transparent text-blueGray-700'
          onClick={(e) => {
            e.preventDefault()
            setLeadClaimEmailModalShow(true)
            setDropdownPopoverShow(false)
          }}
        >
          Send claim lead emails...
        </a>
      </div>
      <LeadInfoModal
        state={leadInfoModalShow}
        setState={setLeadInfoModalShow}
        lead={lead}
      />
      <LeadAssignModal
        state={leadAssignModalShow}
        setState={setLeadAssignModalShow}
        lead={lead}
      />
      <LeadClaimEmailModal
        state={leadClaimEmailModalShow}
        setState={setLeadClaimEmailModalShow}
        lead={lead}
      />
      <LeadDeleteModal
        state={leadDeleteModalShow}
        setState={setLeadDeleteModalShow}
        leadId={lead.id}
      />
      <LeadResendEmailsModal
        state={leadResendEmailsModalShow}
        setState={setLeadResendEmailsModalShow}
        leadId={lead.id}
      />
      <LeadFishyModal
        state={leadFishyModalShow}
        setState={setLeadFishyModalShow}
        leadId={lead.id}
        leadName={lead.name}
      />
    </>
  )
}

export default TableDropdown
