import { createSlice } from '@reduxjs/toolkit'
import { userLogin } from './actions/loginActions'

const initialState = {
  loading: false,
  userInfo: {},
  userToken: null,
  error: null
}

export const loginSlice = createSlice({
  name: 'login',
  initialState,
  reducers: {},
  extraReducers: {
    // login user
    [userLogin.pending]: (state) => {
      state.loading = true
      state.error = null
    },
    [userLogin.fulfilled]: (state, { payload }) => {
      state.loading = false
      state.userInfo = payload
      state.userToken = payload.token
    },
    [userLogin.rejected]: (state, { payload }) => {
      state.loading = false
      state.error = payload
    }
  }
})

// Action creators are generated for each case reducer function
// export const { increment, decrement, incrementByAmount } = loginSlice.actions

export default loginSlice.reducer
