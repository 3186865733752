import React from 'react'
import { BrowserRouter, Route, Routes, Navigate } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { ProvideAuth } from 'views/auth/AuthProvider'

// import '@fortawesome/fontawesome-free/css/all.min.css'
// import 'assets/styles/tailwind.css'

import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'

// layouts

import Admin from 'layouts/Admin.js'
import Auth from 'layouts/Auth.js'

// views without layouts

import Login from 'views/auth/Login.js'
import Landing from 'views/Landing.js'
import Profile from 'views/Profile.js'
import Index from 'views/Index.js'
import ProtectedRoute from 'ProtectedRoutes'

function App () {
  const userToken = useSelector((state) => state.login.userToken)

  return (
    <BrowserRouter>
      <ToastContainer />
      <ProvideAuth>
        <Routes>
          {/* add routes with layouts */}
          <Route path='auth/*' element={<Auth />} />
          <Route element={<ProtectedRoute isSigned={userToken} />}>
            <Route path='admin/*' element={<Admin />} />
          </Route>
          {/* add routes without layouts */}
          <Route element={<ProtectedRoute isSigned={userToken} />}>
            <Route path='landing' exact element={<Landing />} />
          </Route>
          <Route element={<ProtectedRoute isSigned={userToken} />}>
            <Route path='profile' exact element={<Profile />} />
          </Route>
          {/* add Navigate for first page */}
          <Route path='/' element={<Navigate to='/auth/login' />} />
          {/* <Route path='*' element={<Navigate to='/' />} /> */}
        </Routes>
      </ProvideAuth>
    </BrowserRouter>
  )
}

export default App
