import { useEffect, useState } from 'react'
import { getLead } from '../../services'
import Modal from './Modal'

const LeadDataModal = ({ setState, state, leadId }) => {
  const [lead, setLead] = useState(false)

  useEffect(() => {
    if (state) {
      getLead(setLead, leadId)
    }
  }, [state])

  const projects = JSON.parse(window.localStorage.getItem('projects'))
  const consultants = JSON.parse(window.localStorage.getItem('consultants'))

  function getConsultantTypeName (id) {
    for (const consultant of consultants) {
      if (consultant.id === id) { return consultant.name }
    }
    return 1
  }

  function getProjectTypeName (id) {
    for (const project of projects) {
      if (project.id === id) { return project.name }
    }
    return 1
  }

  return (
    <Modal
      state={state} setState={setState}
      customStyle={{
        borderTopLeftRadius: '0.25rem',
        borderTopRightRadius: '0.25rem'
      }}
    >
      <div className='flex'>
        {lead &&
          <div className='flex flex-row flex-wrap gap-x-8'>
            <div className='flex flex-col'>
              <div className='flex flex-row'>
                <label className='font-bold inline'>Name: </label>
                <p className='ml-1'>{lead.name}</p>
              </div>
              <div className='flex flex-row'>
                <label className='font-bold'>Phone: </label>
                <p className='ml-1'>{lead.phone}</p>
              </div>
              <div className='flex flex-row'>
                <label className='font-bold'>Email: </label>
                <p className='ml-1'>{lead.email}</p>
              </div>
              <div className='flex flex-row'>
                <label className='font-bold'>Created at: </label>
                <p className='ml-1'>{lead.createdAt?.split('T')[0] + ' ' + lead.createdAt?.split('T')[1].split('.')[0]}</p>
              </div>
            </div>
            <div className='flex flex-col'>
              <div className='flex flex-row'>
                <label className='font-bold'>Project Type: </label>
                <p className='ml-1'>{getProjectTypeName(lead.projectTypeId)}</p>
              </div>
              <div className='flex flex-row'>
                <label className='font-bold'>Consultant Type: </label>
                <p className='ml-1'>{getConsultantTypeName(lead.consultantTypeId)}</p>
              </div>
              <div className='flex flex-row'>
                <label className='font-bold'>Address: </label>
                <p className='ml-1'>{lead.address}</p>
              </div>
              <div className='flex flex-row'>
                <label className='font-bold'>Message: </label>
                <p className='ml-1'>{lead.message}</p>
              </div>
            </div>
          </div>}
      </div>
    </Modal>
  )
}

export default LeadDataModal
