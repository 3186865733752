import React, { useState, useEffect } from 'react'

// components
import CardTableFakeLeads from 'components/Cards/CardTableFakeLeads.js'
import { getFakeLeads, getUnassignedLeads } from 'services'

export default function FakeLeads () {
  const [fakeLeadsPage, setFakeLeadsPage] = useState(1)
  const [fakeLeads, setFakeLeads] = useState(false)
  const [leadModified, setLeadModified] = useState(false)
  const [rowsPerPage, setRowsPerPage] = useState(10)
  const lastPage = Math.ceil(fakeLeads.count / rowsPerPage)

  useEffect(() => {
    getFakeLeads(setFakeLeads, fakeLeadsPage)
  }, [])

  useEffect(() => {
    getFakeLeads(setFakeLeads, fakeLeadsPage, {
      // limit: document.getElementById('rowsPageOptions').value
      limit: 10
    })
  }, [fakeLeadsPage])

  useEffect(() => {
    if (leadModified) {
      getFakeLeads(setFakeLeads, fakeLeadsPage, {
      // limit: document.getElementById('rowsPageOptions').value
        limit: 10
      })
    }
    setLeadModified(false)
  }, [leadModified])

  const searchLeads = (e) => {
    e.preventDefault()
    getFakeLeads(setFakeLeads, fakeLeadsPage, {
      // limit: document.getElementById('rowsPageOptions').value
      limit: 10
    })
  }

  return (
    <>
      <div className='flex flex-wrap mt-4'>
        <div className='w-full mb-12 px-4'>
          <CardTableFakeLeads
            leads={fakeLeads} setLeads={setFakeLeads}
            page={fakeLeadsPage} setPage={setFakeLeadsPage}
            handleSubmit={searchLeads} setLeadModified={setLeadModified}
            setRowsPerPage={setRowsPerPage}
          />
          {fakeLeads.count > 10 &&
            <div className='w-full flex justify-center align-center space-x-2 '>
              {fakeLeadsPage === 1
                ? <button className='text-2xl font-medium' disabled>{'<'}</button>
                : <button className='text-2xl font-medium' onClick={() => setFakeLeadsPage(fakeLeadsPage - 1)}>{'<'}</button>}
              <span className='text-2xl font-medium'>{fakeLeadsPage} / {lastPage}</span>
              {fakeLeadsPage === lastPage
                ? <button className='text-2xl font-medium' disabled>{'>'}</button>
                : <button className='text-2xl font-medium' onClick={() => setFakeLeadsPage(fakeLeadsPage + 1)}>{'>'}</button>}
            </div>}
        </div>
      </div>
    </>
  )
}
