import React, { useState, useEffect } from 'react'

// components

import CardTable from 'components/Cards/CardTable.js'
import { getAllLeads, getConsultants, getProjects, getUnassignedLeads } from 'services'

export default function Leads () {
  const [allLeadsPage, setAllLeadsPage] = useState(1)
  const [allLeads, setAllLeads] = useState(false)
  const [rowsPerPage, setRowsPerPage] = useState(10)
  const [unassignedFilter, setUnassignedFilter] = useState(false)

  const lastPage = isNaN(rowsPerPage) ? 1 : Math.ceil(allLeads.count / rowsPerPage)

  const getLimitValues = () => {
    const value = document.getElementById('rowsPageOptions').value
    return isNaN(value)
      ? value
      : parseInt(value)
  }

  useEffect(() => {
    getAllLeads(setAllLeads, allLeadsPage)
    getConsultants()
    getProjects()
  }, [])

  useEffect(() => {
    unassignedFilter
      ? getUnassignedLeads(setAllLeads, allLeadsPage, {
        filter: document.getElementById('leads-search').value,
        searchField: document.getElementById('leadSearchFields').value,
        consultantTypeId: document.getElementById('consultantTypesSelect').value,
        projectTypeId: document.getElementById('projectTypesSelect').value,
        limit: getLimitValues()
      })
      : getAllLeads(setAllLeads, allLeadsPage, {
        filter: document.getElementById('leads-search').value,
        searchField: document.getElementById('leadSearchFields').value,
        consultantTypeId: document.getElementById('consultantTypesSelect').value,
        projectTypeId: document.getElementById('projectTypesSelect').value,
        limit: getLimitValues()
      })
  }, [allLeadsPage])

  const searchLeads = (e) => {
    e.preventDefault()
    unassignedFilter
      ? getUnassignedLeads(setAllLeads, allLeadsPage, {
        filter: document.getElementById('leads-search').value,
        searchField: document.getElementById('leadSearchFields').value,
        consultantTypeId: document.getElementById('consultantTypesSelect').value,
        projectTypeId: document.getElementById('projectTypesSelect').value,
        limit: getLimitValues()
      })
      : getAllLeads(setAllLeads, allLeadsPage, {
        filter: document.getElementById('leads-search').value,
        searchField: document.getElementById('leadSearchFields').value,
        consultantTypeId: document.getElementById('consultantTypesSelect').value,
        projectTypeId: document.getElementById('projectTypesSelect').value,
        limit: getLimitValues()
      })
  }

  return (
    <>
      <div className='flex flex-wrap mt-4'>
        <div className='w-full mb-12 px-4'>
          <CardTable
            leads={allLeads} setLeads={setAllLeads}
            page={allLeadsPage} setPage={setAllLeadsPage}
            handleSubmit={searchLeads} setUnassignedFilter={setUnassignedFilter}
            setRowsPerPage={setRowsPerPage}
          />
          {allLeads.count > 10 &&
            <div className='w-full flex justify-center align-center space-x-2 '>
              {allLeadsPage === 1
                ? <button className='text-2xl font-medium' disabled>{'<'}</button>
                : <button className='text-2xl font-medium' onClick={() => setAllLeadsPage(allLeadsPage - 1)}>{'<'}</button>}
              <span className='text-2xl font-medium'>{allLeadsPage} / {lastPage}</span>
              {allLeadsPage === lastPage
                ? <button className='text-2xl font-medium' disabled>{'>'}</button>
                : <button className='text-2xl font-medium' onClick={() => setAllLeadsPage(allLeadsPage + 1)}>{'>'}</button>}
            </div>}
        </div>
        {/* <div className="w-full mb-12 px-4"> */}
        {/*   <CardTable color="dark" /> */}
        {/* </div> */}
      </div>
    </>
  )
}
