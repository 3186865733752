import { useEffect, useState } from 'react'
import { getBusinessesAssignedTo, getEmailsAssignedToLead, unassign } from '../../services'
import Modal from './Modal'

const LeadInfoModal = ({ setState, state, lead }) => {
  const [leadBusinesses, setLeadBusinesses] = useState([])
  const [leadEmails, setLeadEmails] = useState([])

  useEffect(() => {
    if (state) {
      getBusinessesAssignedTo(setLeadBusinesses, lead.id)
      getEmailsAssignedToLead(setLeadEmails, lead.id)
    }
  }, [state])

  return (
    <Modal
      state={state} setState={setState}
      customStyle={{
        borderTopLeftRadius: '0.25rem',
        borderTopRightRadius: '0.25rem'
      }}
    >
      <div className='flex flex-column'>
        <div className='w-1/2'>
          <h1 className='font-bold text-xl mb-4'>Assigned to:</h1>
          {leadBusinesses &&
           leadBusinesses.map((el) =>
             <p key={el.id}>
               {el.name}
               <button
                 onClick={async () => {
                   unassign(lead.id, el.id)
                   await getBusinessesAssignedTo(setLeadBusinesses, lead.id)
                 }}
                 className={`ml-2 ${el.subscription ? 'bg-emerald-400' : 'bg-red-300'} rounded-t py-0.5 px-1`}
               >
                 x
               </button>
             </p>)}
        </div>
        <div className='w-1/2'>
          <h1 className='font-bold text-xl mb-4'>Emails sent:</h1>
          {leadEmails &&
           leadEmails.map((el) =>
             <p key={el.id}>
               <strong>{el.business.name}</strong> - {el.title} - {el.createdAt.slice(0, -5)}
             </p>
           )}
        </div>
      </div>
    </Modal>
  )
}

export default LeadInfoModal
