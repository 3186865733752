import { useEffect, useState } from 'react'
import { getBusinessLeads, unassign } from '../../services'
import Modal from './Modal'

const BusinessLeadsModal = ({ setState, state, business }) => {
  const [businessLeads, setBusinessLeads] = useState(false)
  const [page, setPage] = useState(1)
  const [max, setMax] = useState(false)

  const projects = JSON.parse(window.localStorage.getItem('projects'))
  const consultants = JSON.parse(window.localStorage.getItem('consultants'))

  function getConsultantTypeName (id) {
    for (const consultant of consultants) {
      if (consultant.id === id) { return consultant.name }
    }
    return 1
  }

  function getProjectTypeName (id) {
    for (const project of projects) {
      if (project.id === id) { return project.name }
    }
    return 1
  }

  useEffect(() => {
    state && getBusinessLeads(setBusinessLeads, business.id, page, setMax)
  }, [state])

  return (
    <Modal
      state={state} setState={setState}
      customStyle={{
        borderTopLeftRadius: '0.25rem',
        borderTopRightRadius: '0.25rem'
      }}
    >
      <div className='flex flex-col'>
        <h1 className='font-bold text-xl mb-4'>Leads from {business.name}:</h1>
        {businessLeads && businessLeads?.rows.length > 0 && businessLeads?.rows.map((b, i) => {
          return (
            <div key={i} className='flex flex-col gap-x-2'>
              <div className='flex flex-row flex-wrap gap-x-8'>
                <div className='flex flex-col'>
                  <div className='flex flex-row'>
                    <label className='font-bold inline'>Name: </label>
                    <p className='ml-1'>{b.name}</p>
                  </div>
                  <div className='flex flex-row'>
                    <label className='font-bold'>Phone: </label>
                    <p className='ml-1'>{b.phone}</p>
                  </div>
                  <div className='flex flex-row'>
                    <label className='font-bold'>Email: </label>
                    <p className='ml-1'>{b.email}</p>
                  </div>
                  <div className='flex flex-row'>
                    <label className='font-bold'>Created at: </label>
                    <p className='ml-1'>{b.createdAt?.split('T')[0] + ' ' + b.createdAt?.split('T')[1].split('.')[0]}</p>
                  </div>
                </div>
                <div className='flex flex-col'>
                  <div className='flex flex-row'>
                    <label className='font-bold'>Project Type: </label>
                    <p className='ml-1'>{getProjectTypeName(b.projectTypeId)}</p>
                  </div>
                  <div className='flex flex-row'>
                    <label className='font-bold'>Consultant Type: </label>
                    <p className='ml-1'>{getConsultantTypeName(b.consultantTypeId)}</p>
                  </div>
                  <div className='flex flex-row'>
                    <label className='font-bold'>Address: </label>
                    <p className='ml-1'>{b.address}</p>
                  </div>
                  <div className='flex flex-row'>
                    <label className='font-bold'>Message: </label>
                    <p className='ml-1'>{b.message}</p>
                  </div>
                </div>
              </div>
              <div>
                <button
                  className='ml-2 mt-2 bg-red-300 rounded py-0.5 px-1'
                  onClick={async () => {
                    unassign(b.id, business.id)
                    setState(false)
                  }}
                >
                  Unassign
                </button>
              </div>
              <hr className='my-4' />
            </div>
          )
        })}
      </div>
    </Modal>
  )
}

export default BusinessLeadsModal
