import { toast } from 'react-toastify'
import { resendLeadRegistration } from '../../services'
import Modal from './Modal'

const LeadResendEmailsModal = ({ setState, state, leadId }) => {
  return (
    <Modal
      state={state} setState={setState}
      customStyle={{
        borderTopLeftRadius: '0.25rem',
        borderTopRightRadius: '0.25rem'
      }}
    >
      <div className='flex flex-column justify-center'>
        <div>
          <h1 className='font-bold text-xl mb-4'>Are you sure you want to resend the emails?</h1>
          <div className='flex justify-evenly'>
            <button
              onClick={async () => {
                await resendLeadRegistration(leadId)
                setState(false)
                toast.success('Emails resent succesfully')
              }}
              className='ml-2 bg-green-300 rounded-t py-0.5 px-1'
            >
              Resend
            </button>
            <button
              onClick={async () => {
                setState(false)
              }}
              className='ml-2 bg-grey-300 rounded-t py-0.5 px-1'
            >
              Cancel
            </button>
          </div>
        </div>
      </div>
    </Modal>
  )
}

export default LeadResendEmailsModal
