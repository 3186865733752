import { useEffect, useState } from 'react'
import { getBusinessEmails } from '../../services'
import Modal from './Modal'

const BusinessEmailModal = ({ setState, state, business }) => {
  const [businessEmails, setBusinessEmails] = useState(false)

  useEffect(() => {
    state && getBusinessEmails(setBusinessEmails, business.id)
  }, [state])

  return (
    <Modal
      state={state} setState={setState}
      customStyle={{
        borderTopLeftRadius: '0.25rem',
        borderTopRightRadius: '0.25rem'
      }}
    >
      <div className='flex flex-col'>
        <h1 className='font-bold text-xl mb-4'>Emails:</h1>
        {businessEmails && businessEmails.map((e, i) => {
          return (
            <>
              <p key={i} className='py-2'>{e.title} - {e.createdAt}</p>
              <hr />
            </>
          )
        })}
      </div>
    </Modal>
  )
}

export default BusinessEmailModal
