import { createAsyncThunk } from '@reduxjs/toolkit'
import { signIn } from 'services'

export const userLogin = createAsyncThunk(
  'admin/login',
  async (userData) => {
    try {
      const { data } = await signIn(userData)
      // store user's token in local storage
      localStorage.setItem('userToken', data.token)
      return data
    } catch (error) {
      // return custom error message from API if any
      if (error.response && error.response.data.message) {
        return { error: error.response.data.message }
      } else {
        return { error: error.message }
      }
    }
  }
)
