import React, { useState, useEffect } from 'react'

// components

import CardTableExperts from 'components/Cards/CardTableExperts.js'
import { getBusinesses, getBusinessesNoAreas, getConsultants, getProjects } from 'services'

export default function Experts () {
  const consultants = JSON.parse(window.localStorage.getItem('consultants'))

  const [allBusinessesPage, setAllBusinessesPage] = useState(1)
  const [allBusinesses, setAllBusinesses] = useState(false)
  const [businessModified, setBusinessModified] = useState(false)
  const [rowsPerPage, setRowsPerPage] = useState(10)
  const [noAreasFilter, setNoAreasFilter] = useState(false)
  const [noConsultantsFilter, setNoConsultantsFilter] = useState(false)
  const [searchConsultants, setSearchConsultants] = useState(consultants && consultants?.map(c => ({ ...c, active: false })))

  const lastPage = isNaN(rowsPerPage) ? 1 : Math.ceil(allBusinesses.count / rowsPerPage)

  const handleConsultantChecked = (e, consultantId) => {
    e.preventDefault()
    setSearchConsultants(prev => prev.map(c => (c.id === consultantId) ? { ...c, active: !c.active } : c))
  }
  const handleNoConsultantFilterChecked = (e) => {
    e.preventDefault()
    setNoConsultantsFilter(prev => !prev)
    setSearchConsultants(consultants && consultants?.map(c => ({ ...c, active: false })))
  }

  const getLimitValues = () => {
    const value = document.getElementById('rowsPageOptions').value
    return isNaN(value)
      ? value
      : parseInt(value)
  }

  useEffect(() => {
    getBusinesses(setAllBusinesses, allBusinessesPage)
    getConsultants()
    getProjects()
  }, [])

  useEffect(() => {
    businessModified &&
      (noAreasFilter
        ? getBusinessesNoAreas(setAllBusinesses, allBusinessesPage, {
          filter: document.getElementById('business-search').value,
          searchField: document.getElementById('searchFields').value,
          limit: getLimitValues(),
          consultantTypeId: noConsultantsFilter
            ? 'noConsultantsFilter'
            : searchConsultants
              .filter(c => c.active)
              .map(c => c.id)
        })

        : getBusinesses(setAllBusinesses, allBusinessesPage, {
          filter: document.getElementById('business-search').value,
          searchField: document.getElementById('searchFields').value,
          limit: getLimitValues(),
          consultantTypeId: noConsultantsFilter
            ? 'noConsultantsFilter'
            : searchConsultants
              .filter(c => c.active)
              .map(c => c.id)
        })
      )
    setBusinessModified(false)
  }, [businessModified])

  useEffect(() => {
    noAreasFilter
      ? getBusinessesNoAreas(setAllBusinesses, allBusinessesPage, {
        filter: document.getElementById('business-search').value,
        searchField: document.getElementById('searchFields').value,
        limit: getLimitValues(),
        consultantTypeId: noConsultantsFilter
          ? 'noConsultantsFilter'
          : searchConsultants
            .filter(c => c.active)
            .map(c => c.id)
      })
      : getBusinesses(setAllBusinesses, allBusinessesPage, {
        filter: document.getElementById('business-search').value,
        searchField: document.getElementById('searchFields').value,
        limit: getLimitValues(),
        consultantTypeId: noConsultantsFilter
          ? 'noConsultantsFilter'
          : searchConsultants
            .filter(c => c.active)
            .map(c => c.id)
      })
  }, [allBusinessesPage])

  const searchBusinesses = (e) => {
    e.preventDefault()
    noAreasFilter
      ? getBusinessesNoAreas(setAllBusinesses, allBusinessesPage, {
        filter: document.getElementById('business-search').value,
        searchField: document.getElementById('searchFields').value,
        limit: getLimitValues(),
        consultantTypeId: noConsultantsFilter
          ? 'noConsultantsFilter'
          : searchConsultants
            .filter(c => c.active)
            .map(c => c.id)
      })
      : getBusinesses(setAllBusinesses, allBusinessesPage, {
        filter: document.getElementById('business-search').value,
        searchField: document.getElementById('searchFields').value,
        limit: getLimitValues(),
        consultantTypeId: noConsultantsFilter
          ? 'noConsultantsFilter'
          : searchConsultants
            .filter(c => c.active)
            .map(c => c.id)
      })
  }

  return (
    <>
      <div className='flex flex-wrap mt-4'>
        <div className='w-full mb-12 px-4'>
          <CardTableExperts
            businesses={allBusinesses}
            page={allBusinessesPage} setPage={setAllBusinessesPage}
            handleSubmit={searchBusinesses}
            setRowsPerPage={setRowsPerPage}
            setBusinessModified={setBusinessModified}
            handleConsultantChecked={handleConsultantChecked}
            searchConsultants={searchConsultants}
            setNoAreasFilter={setNoAreasFilter}
            noConsultantsFilter={noConsultantsFilter}
            handleNoConsultantFilterChecked={handleNoConsultantFilterChecked}
          />
          {allBusinesses.count > 10 &&
            <div className='w-full flex justify-center align-center space-x-2 '>
              {allBusinessesPage === 1
                ? <button className='text-2xl font-medium' disabled>{'<'}</button>
                : <button className='text-2xl font-medium' onClick={() => setAllBusinessesPage(allBusinessesPage - 1)}>{'<'}</button>}
              <span className='text-2xl font-medium'>{allBusinessesPage} / {lastPage}</span>
              {allBusinessesPage === lastPage
                ? <button className='text-2xl font-medium' disabled>{'>'}</button>
                : <button className='text-2xl font-medium' onClick={() => setAllBusinessesPage(allBusinessesPage + 1)}>{'>'}</button>}
            </div>}
        </div>
        {/* <div className="w-full mb-12 px-4"> */}
        {/*   <CardTableExperts color="dark" /> */}
        {/* </div> */}
      </div>
    </>
  )
}
