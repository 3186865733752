import axios from 'axios'

const expertPlansApi = axios.create({
  baseURL: process.env.REACT_APP_HOST_API,
  withCredentials: true
})

// export const renew = async () => {
//   try {
//     await expertPlansApi({
//       method: 'get',
//       url: '/businesses/auth/renew'
//     })

//     return true
//   } catch (error) {
//     console.log(error)
//     return false
//   }
// }

export const getUnassignedLeads = async (setLeads, page, body) => {
  try {
    const { data } = await expertPlansApi({
      method: 'post',
      url: `/admins/leads/unassigned/${page}`,
      data: body
    })
    setLeads(data)
  } catch (error) {
    console.log(error.response)
  }
}

export const unassign = async (leadId, businessId) => {
  try {
    const { data } = await expertPlansApi({
      method: 'delete',
      url: `/admins/leads/unassign/${businessId}/${leadId}`
    })
    alert('Lead unassigned')
  } catch (error) {
    console.log(error.response)
  }
}

export const getAllLeads = async (setLeads, page, body = { filter: '' }) => {
  try {
    const { data } = await expertPlansApi({
      method: 'post',
      url: `/admins/leads/getAll/${page}`,
      data: body
    })
    setLeads(data)
  } catch (error) {
    console.log(error.response)
  }
}

export const getLead = async (setLead, id) => {
  try {
    const { data } = await expertPlansApi({
      method: 'get',
      url: `/admins/leads/getById/${id}`
    })
    setLead(data)
  } catch (error) {
    console.log(error.response)
  }
}

export const getFakeLeads = async (setLeads, page, body) => {
  try {
    const { data } = await expertPlansApi({
      method: 'post',
      url: `/admins/leads/getFake/${page}`,
      data: body
    })
    setLeads(data)
  } catch (error) {
    console.log(error.response)
  }
}

export const getProjects = async () => {
  try {
    const { data } = await expertPlansApi({
      method: 'get',
      url: '/projectTypes'
    })
    window.localStorage.setItem('projects', JSON.stringify(data))
  } catch (error) {
    console.log(error.response)
  }
}
export const getConsultants = async () => {
  try {
    const dataConsultants = (await expertPlansApi({
      method: 'get',
      url: '/consultantTypes/all'
    })).data
    window.localStorage.setItem('consultants', JSON.stringify(dataConsultants))
  } catch (error) {
    console.log(error.response)
  }
}

export const getBusinesses = async (setBusinesses, expertPage, body = { filter: '' }) => {
  try {
    const { data } = await expertPlansApi({
      method: 'post',
      url: `/admins/businesses/${expertPage}`,
      data: body
    })
    setBusinesses(data)
  } catch (error) {
    console.log(error)
    console.log(error.response)
  }
}

export const resendEmailVerification = async (businessId) => {
  try {
    const { data } = await expertPlansApi({
      method: 'get',
      url: `/admins/businesses/resendEmailVerification/${businessId}`
    })
  } catch (error) {
    console.log(error)
    console.log(error.response)
  }
}

export const markLeadAsGenuine = async (leadId) => {
  try {
    console.log(leadId)
    const { data } = await expertPlansApi({
      method: 'post',
      url: `/admins/leads/markAsGenuine/${leadId}`
    })
  } catch (error) {
    console.log(error)
    console.log(error.response)
  }
}

export const getBusinessesNoAreas = async (setBusinesses, expertPage, body = { filter: '' }) => {
  try {
    const { data } = await expertPlansApi({
      method: 'post',
      url: `/admins/businesses/noArea/${expertPage}`,
      data: body
    })
    setBusinesses(data)
  } catch (error) {
    console.log(error)
    console.log(error.response)
  }
}

export const getBusinessLeads = async (setLeads, id, page, setMax) => {
  try {
    const { data } = await expertPlansApi({
      method: 'get',
      url: `/admins/leads/get/${id}/${page}`
    })
    if (data === '') {
      setLeads(false)
      setMax(false)
    } else {
      setMax(Math.ceil(data.count / 100))
      setLeads(data)
    }
  } catch (error) {
    console.log(error.response)
  }
}

export const getBusinessConsultants = async (setConsultants, id) => {
  try {
    const { data } = await expertPlansApi({
      method: 'get',
      url: `/admins/businesses/consultants/${id}`
    })
    if (data === '') {
      setConsultants(false)
    } else {
      setConsultants(data)
    }
  } catch (error) {
    console.log(error.response)
  }
}

export const getBusinessAreas = async (setAreas, id) => {
  try {
    const { data } = await expertPlansApi({
      method: 'get',
      url: `/admins/areas/${id}`
    })
    if (data === '') {
      setAreas(false)
    } else {
      setAreas(data)
    }
  } catch (error) {
    console.log(error.response)
  }
}

export const getBusinessEmails = async (setEmails, businessId) => {
  try {
    const { data } = await expertPlansApi({
      method: 'post',
      url: '/admins/emailLogs',
      data: { businessId }
    })
    setEmails(data)
  } catch (error) {
    console.log(error.response)
  }
}

export const sendClaimLeadEmail = async (businesses, leadId) => {
  try {
    return await expertPlansApi({
      method: 'post',
      url: `/admins/leads/sendClaimLeadEmail/${leadId}`,
      data: businesses
    })
  } catch (error) {
    console.log(error.response)
  }
}

export const sendClaimEmailAdmin = async (leadId, businesses) => {
  try {
    return await expertPlansApi({
      method: 'post',
      url: '/admins/leads/sendClaimEmailAdmin',
      data: { leadId, businesses }
    })
  } catch (error) {
    console.log(error.response)
  }
}

export const sendFishyEmails = async (businesses, leadId) => {
  try {
    return await expertPlansApi({
      method: 'post',
      url: `/admins/leads/sendFishyEmail/${leadId}`,
      data: businesses
    })
  } catch (error) {
    console.log(error.response)
  }
}

export const assignLeadBusinesses = async (businesses, leadId) => {
  try {
    return await expertPlansApi({
      method: 'post',
      url: `/admins/leads/assign/${leadId}`,
      data: businesses
    })
  } catch (error) {
    console.log(error.response)
  }
}

export const getAllBusinesses = async (setBusinesses) => {
  try {
    const { data } = await expertPlansApi({
      method: 'get',
      url: '/admins/allBusinesses'
    })
    setBusinesses(data)
  } catch (error) {
    console.log(error.response)
  }
}

export const getAllBusinessesMap = async (setBusinesses) => {
  try {
    const { data } = await expertPlansApi({
      method: 'get',
      url: '/admins/allBusinessesMap'
    })
    setBusinesses(data)
  } catch (error) {
    console.log(error.response)
  }
}

export const getAllUnassignedMap = async (setUnassigned) => {
  try {
    const { data } = await expertPlansApi({
      method: 'get',
      url: '/admins/leads/allUnassigned'
    })
    setUnassigned(data)
  } catch (error) {
    console.log(error.response)
  }
}

export const getBusinessesAssignedTo = async (setBusinesses, leadId) => {
  try {
    const { data } = await expertPlansApi({
      method: 'get',
      url: `/admins/leads/getAssignedTo/${leadId}`
    })
    setBusinesses(data)
  } catch (error) {
    console.log(error.response)
  }
}

export const getEmailsAssignedToLead = async (setEmails, leadId) => {
  try {
    const { data } = await expertPlansApi({
      method: 'post',
      url: '/admins/emailLogs',
      data: { leadId }
    })
    setEmails(data)
  } catch (error) {
    console.log(error.response)
  }
}

export const deleteLead = async (id) => {
  try {
    await expertPlansApi({
      method: 'delete',
      url: `/admins/leads/${id}`
    })
  } catch (error) {
    console.log(error.response)
  }
}

export const deleteExpert = async (id) => {
  try {
    await expertPlansApi({
      method: 'delete',
      url: `/admins/businesses/remove/${id}`
    })
  } catch (error) {
    console.log(error.response)
  }
}

export const updateExpert = async (expert) => {
  try {
    await expertPlansApi({
      method: 'put',
      url: '/admins/businesses',
      data: expert
    })
  } catch (error) {
    console.log(error.response)
  }
}

export const getMapData = async (setMap) => {
  try {
    const { data } = await expertPlansApi({
      method: 'get',
      url: '/admins/allBusinesses'
    })
    setMap(data)
  } catch (error) {
    console.log(error.response)
  }
}

export const signIn = async (data) => {
  try {
    const response = await expertPlansApi({
      url: '/admins/login',
      method: 'post',
      data
    })
    return response
  } catch (error) {
    return {
      type: 'error',
      error,
      message: error.response.data.message
    }
  }
}
export const signOut = async (data) => {
  try {
    const response = await expertPlansApi({
      url: '/admins/logout',
      method: 'get',
      data
    })
    return response
  } catch (error) {
    return {
      type: 'error',
      error,
      message: error.response.data.message
    }
  }
}

export const resendLeadRegistration = async (leadId) => {
  try {
    return await expertPlansApi({
      method: 'get',
      url: `/admins/leads/resendRegistration/${leadId}`
    })
  } catch (error) {
    console.log(error.response)
  }
}

export const selectBusinessConsultants = async (consultantTypes, businessId) => {
  try {
    return await expertPlansApi({
      method: 'post',
      url: `/admins/businesses/selectConsultants/${businessId}`,
      data: consultantTypes
    })
  } catch (error) {
    console.log(error.response)
  }
}
