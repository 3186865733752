import { useEffect, useState, useCallback } from 'react'
import { getBusinessAreas } from '../../services'
import Modal from './Modal'
import { GoogleMap, useJsApiLoader, Circle } from '@react-google-maps/api'

const BusinessAreasModal = ({ setState, state, business }) => {
  const [businessAreas, setBusinessAreas] = useState(false)
  const [markers, setMarkers] = useState([])

  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY
  })

  const containerStyle = {
    minWidth: '300px',
    height: '600px'
  }

  const center = {
    lat: 52.48681447157704,
    lng: -1.8773693070026933
  }

  const checkRepeatedAreas = (array, area) => {
    return array.some(c => c.props.center.lat === area.lat && c.props.center.lng === area.long)
  }

  useEffect(() => {
    state && getBusinessAreas(setBusinessAreas, business.id)
  }, [state])

  useEffect(() => {
    businessAreas && businessAreas?.forEach(area => {
      const isCirclePresent = checkRepeatedAreas(markers, area)
      if (!isCirclePresent) {
        setMarkers(prevArray =>
          [...prevArray,
            <Circle
              center={{
                lat: area.lat,
                lng: area.long
              }}
              radius={
             Number(area.radius) * 1000
           }
            />
          ])
      }
    })
  }, [businessAreas])

  const [, setMap] = useState(null)

  const onLoad = useCallback(function callback (map) {
    setMap(map)
  }, [])

  const onUnmount = useCallback(function callback (map) {
    setMap(null)
  }, [])

  return (
    <Modal
      state={state} setState={setState}
      customStyle={{
        borderTopLeftRadius: '0.25rem',
        borderTopRightRadius: '0.25rem'
      }}
    >
      <div className='flex flex-col'>
        <h1 className='font-bold text-xl mb-4'>Areas of {business.name}: ({businessAreas.length} total)</h1>
        {isLoaded
          ? (
            <center>
              <br />
              <br />
              <GoogleMap
                mapContainerStyle={containerStyle}
                center={center}
                zoom={7}
                onUnmount={onUnmount}
                onLoad={onLoad}
              >
                {markers}
              </GoogleMap>
            </center>
            )
          : (
            <div>There's been an error when loading the areas.</div>
            )}

      </div>
    </Modal>
  )
}

export default BusinessAreasModal
