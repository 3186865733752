import { useNavigate } from 'react-router-dom'
import { useEffect, useContext, createContext, useState } from 'react'
import { signIn } from 'services'

const AuthContext = createContext(null)

export function ProvideAuth ({ children }) {
  const auth = useProvideAuth()
  return <AuthContext.Provider value={auth}>{children}</AuthContext.Provider>
}

export const useAuth = () => {
  return useContext(AuthContext)
}

function useProvideAuth () {
  const navigate = useNavigate()

  const [token, setToken] = useState(null)

  const handleLogin = async (userData) => {
    const response = await signIn(userData)

    setToken(response.token)
    navigate('/admin/dashboard')
  }

  const handleLogout = () => {
    setToken(null)
  }

  const value = {
    token,
    onLogin: handleLogin,
    onLogout: handleLogout
  }

  // useEffect(() => {
  //   if (token) {
  //     setToken(token)
  //   } else {
  //     navigate('/auth')
  //   }
  // }, [])

  return {
    token,
    handleLogin,
    handleLogout
  }
}
