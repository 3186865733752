import { Navigate, Outlet } from 'react-router-dom'

const ProtectedRoute = ({
  isSigned,
  redirectPath = '/',
  children
}) => {
  if (!isSigned) {
    return <Navigate to={redirectPath} replace />
  }

  return children || <Outlet />
}

export default ProtectedRoute
