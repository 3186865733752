import React from 'react'
import PropTypes from 'prop-types'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEnvelopeCircleCheck, faSackDollar, faLocationDot, faBriefcase, faUserXmark, faPause } from '@fortawesome/free-solid-svg-icons'

// components

import TableExpertsDropdown from 'components/Dropdowns/TableExpertsDropdown'

export default function CardTable ({
  color,
  businesses,
  page,
  setPage,
  handleSubmit,
  setRowsPerPage,
  setBusinessModified,
  handleConsultantChecked,
  searchConsultants,
  setNoAreasFilter,
  noConsultantsFilter,
  handleNoConsultantFilterChecked
}) {
  const searchFields = [
    'representativeName',
    'representativeLastName',
    'phone',
    'email',
    'address'
  ]

  const rowsPerPageOptions = [
    25,
    50,
    100,
    'all'
  ]
  const activeButton = 'py-2 px-4 shadow-md no-underline rounded-full bg-blue-700 text-white font-semibold text-sm border-blue btn-primary focus:outline-none active:shadow-none mr-2'
  const unactiveButton = 'py-2 px-4 shadow-md no-underline rounded-full bg-white-700 text-blue-700 font-semibold text-sm border-blue btn-primary focus:outline-none active:shadow-none mr-2'

  return (
    <>
      <div
        className={
          'relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded ' +
          (color === 'light' ? 'bg-white' : 'bg-lightBlue-900 text-white')
        }
      >
        <div className='rounded-t mb-0 px-4 py-3 border-0'>
          <div className='flex flex-wrap items-center'>
            <div className='relative w-full px-4 max-w-full flex-grow flex-1'>
              <h3
                className={
                  'font-semibold text-lg ' +
                  (color === 'light' ? 'text-blueGray-700' : 'text-white')
                }
              >
                Experts ({businesses.count || 0} total)
              </h3>
              <form
                className='md:flex hidden flex-row flex-wrap items-center lg:ml-10 mr-3'
                onSubmit={(e) => handleSubmit(e)}
              >
                <div className='flex flex-col w-full'>
                  <div className='flex flex-row'>
                    <div className='relative w-1/2'>
                      <div className='absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none'>
                        <svg aria-hidden='true' className='w-5 h-5 text-gray-500 dark:text-gray-400' fill='none' stroke='currentColor' viewBox='0 0 24 24' xmlns='http://www.w3.org/2000/svg'><path stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z' /></svg>
                      </div>
                      <input
                        type='search' id='business-search' className='block w-full p-4 pl-10 text-sm text-gray-900 border border-gray-300 rounded-lg bg-gray-50 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500'
                        placeholder='Search Experts...'
                      />
                      <button type='submit' className='text-white absolute right-2.5 bottom-2.5 bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-4 py-2 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800'>Search</button>
                    </div>
                    <select id='searchFields' className='w-1/2 bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500'>
                      <option selected value='name'>name</option>
                      {searchFields.map((s, i) => {
                        return <option key={i} value={s}>{s.replace(/([A-Z])/g, ' $1').trim()}</option>
                      })}
                    </select>
                  </div>
                  <div className='flex flex-wrap items-center gap-y-2 my-2'>
                    {searchConsultants && searchConsultants.map((c, i) => {
                      return (
                        <button
                          key={i}
                          className={c.active ? activeButton : unactiveButton}
                          onClick={(e) => { !noConsultantsFilter && handleConsultantChecked(e, c.id) }}
                        >
                          {c.name}
                        </button>
                      )
                    })}
                    <button
                      className={noConsultantsFilter ? activeButton : unactiveButton}
                      onClick={(e) => handleNoConsultantFilterChecked(e)}
                    >
                      No consultants
                    </button>
                  </div>
                  <div className='flex gap-x-8 items-center'>
                    <div className='flex flex-row items-center my-2'>
                      <label>Show</label>
                      <select
                        id='rowsPageOptions'
                        className='w-24 bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500'
                        onChange={(e) => setRowsPerPage(e.target.value)}
                      >
                        <option selected value={10}>10</option>
                        {rowsPerPageOptions.map((r, i) => {
                          return <option key={i} value={r}>{r}</option>
                        })}
                      </select>
                      <label>fields</label>
                    </div>
                    <div className='flex items-center'>
                      <input
                        id='noAreasCheckbox' type='checkbox' value=''
                        onClick={() => setNoAreasFilter(current => !current)}
                        className='w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600'
                      />
                      <label for='unassignedLeadsCheckbox' className='ml-2 text-sm font-medium text-gray-900 dark:text-gray-300'>See businesses with no areas</label>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
        <div className='block w-full overflow-x-auto'>
          {/* Projects table */}
          <table className='items-center w-full bg-transparent border-collapse'>
            <thead>
              <tr>
                <th
                  className={
                    'px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left ' +
                    (color === 'light'
                      ? 'bg-blueGray-50 text-blueGray-500 border-blueGray-100'
                      : 'bg-lightBlue-800 text-lightBlue-300 border-lightBlue-700')
                  }
                >
                  Date
                </th>
                <th
                  className={
                    'px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 font-semibold text-left ' +
                    (color === 'light'
                      ? 'bg-blueGray-50 text-blueGray-500 border-blueGray-100'
                      : 'bg-lightBlue-800 text-lightBlue-300 border-lightBlue-700')
                  }
                >
                  Business Info
                </th>
                <th
                  className={
                    'px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left ' +
                    (color === 'light'
                      ? 'bg-blueGray-50 text-blueGray-500 border-blueGray-100'
                      : 'bg-lightBlue-800 text-lightBlue-300 border-lightBlue-700')
                  }
                >
                  Rep. Info
                </th>
                <th
                  className={
                    'px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left ' +
                    (color === 'light'
                      ? 'bg-blueGray-50 text-blueGray-500 border-blueGray-100'
                      : 'bg-lightBlue-800 text-lightBlue-300 border-lightBlue-700')
                  }
                >
                  Leads
                </th>
                <th
                  className={
                    'px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left ' +
                    (color === 'light'
                      ? 'bg-blueGray-50 text-blueGray-500 border-blueGray-100'
                      : 'bg-lightBlue-800 text-lightBlue-300 border-lightBlue-700')
                  }
                >
                  Status
                </th>
                {/* <th */}
                {/*   className={ */}
                {/*     'px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left ' + */}
                {/*     (color === 'light' */}
                {/*       ? 'bg-blueGray-50 text-blueGray-500 border-blueGray-100' */}
                {/*       : 'bg-lightBlue-800 text-lightBlue-300 border-lightBlue-700') */}
                {/*   } */}
                {/* > */}
                {/*   Consultants */}
                {/* </th> */}
                <th
                  className={
                    'px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left ' +
                    (color === 'light'
                      ? 'bg-blueGray-50 text-blueGray-500 border-blueGray-100'
                      : 'bg-lightBlue-800 text-lightBlue-300 border-lightBlue-700')
                  }
                />
              </tr>
            </thead>

            <tbody>
              {businesses && businesses.rows.map((business, i) =>
                <ExpertsRow setBusinessModified={setBusinessModified} key={business.name + i} business={business} />
              )}
            </tbody>
          </table>
        </div>
      </div>
    </>
  )
}

CardTable.defaultProps = {
  color: 'light'
}

CardTable.propTypes = {
  color: PropTypes.oneOf(['light', 'dark'])
}

const ExpertsRow = ({ color, business, setBusinessModified, key }) => {
  // const MAX_CONSULTANT_TYPES = 4
  // const [showAllConsultantTypes, setShowAllConsultantTypes] = useState(false)

  // const slicedConsultantTypes = business.consultantTypes.slice(0, MAX_CONSULTANT_TYPES)
  // const remainingConsultantTypes = business.consultantTypes.slice(MAX_CONSULTANT_TYPES)

  // const handleShowMore = () => {
  //   setShowAllConsultantTypes(true)
  // }
  // const handleShowLess = () => {
  //   setShowAllConsultantTypes(false)
  // }

  return (
    <tr key={key} className='border-b border-black-100'>
      <td className='border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4'>
        <div className='flex items-center'>
          <span className='mr-2'>
            {business.createdAt.split('T')[0].split(',')[0]}
            <br />
            {business.createdAt.split('T')[0].split(',')[1]}
          </span>
        </div>
      </td>
      <td className='border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4'>
        <div className='flex flex-col'>
          <div>
            <FontAwesomeIcon
              icon={faBriefcase}
            />
            <span
              className={
                'ml-3 font-normal ' +
                  +(color === 'light' ? 'text-blueGray-600' : 'text-white')
              }
            >
              {business.name}
            </span>
          </div>
          <div>
            <FontAwesomeIcon
              icon={faLocationDot} style={{ width: '12px' }}
            />
            <span
              className={
                'ml-3 font-normal ' +
                  +(color === 'light' ? 'text-blueGray-600' : 'text-white')
              }
            >
              {business.address}
            </span>
          </div>
        </div>
      </td>
      <td className='border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs p-4'>
        <div className='flex flex-col'>
          {/* <img */}
          {/*   src={require('assets/img/bootstrap.jpg').default} */}
          {/*   className='h-12 w-12 bg-white rounded-full border' */}
          {/*   alt='...' */}
          {/* />{' '} */}
          <div>
            <i className='fas fa-user-tie' />
            <span
              className={
               'ml-3 font-normal ' +
                 +(color === 'light' ? 'text-blueGray-600' : 'text-white')
             }
            >
              {business.representativeName}
              {' '}
              {business.representativeLastName}
            </span>
          </div>
          <div>
            <i className='fas fa-phone' />
            <span
              className={
               'ml-3 font-normal break-all ' +
                 +(color === 'light' ? 'text-blueGray-600' : 'text-white')
             }
            >
              {business.phone}
            </span>
          </div>
          <div>
            <i className='fas fa-envelope' />
            <span
              className={
               'ml-3 font-normal break-all ' +
                 +(color === 'light' ? 'text-blueGray-600' : 'text-white')
             }
            >
              {business.email}
            </span>
          </div>
        </div>
      </td>
      <td className='border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs p-4'>
        <div>
          {/* <i className='fas fa-envelope' /> */}
          <span
            className={
              'font-normal break-all ' +
                +(color === 'light' ? 'text-blueGray-600' : 'text-white')
            }
          >
            {business.leadsConsumed + ' / ' + business.leadLimit}
          </span>
        </div>
      </td>
      <td className='border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4'>
        <div className='h-12 flex flex-wrap flex-col items-center'>
          <div>
            <FontAwesomeIcon
              icon={faEnvelopeCircleCheck}
              className={`w-4 h-auto ${business.emailVerified ? 'text-green-500' : 'text-red-500'}`}
            />
          </div>
          <div>
            <FontAwesomeIcon
              icon={faSackDollar}
              className={`w-4 h-auto ${business.subscription ? 'text-green-500' : 'text-red-500'}`}
            />
          </div>
          {business.userVerified !== 1 &&
            <div>
              <FontAwesomeIcon
                icon={faUserXmark}
                className={`w-4 h-auto
                ${business.userVerified === 2
                  ? 'text-orange-500'
                  : business.userVerified === 3 && 'text-red-500'}`}
              />
            </div>}
          {!!business.globalLeadPause &&
            <div>
              <FontAwesomeIcon
                icon={faPause}
                className='w-3 h-auto text-red-500'
              />
            </div>}
        </div>
      </td>
      {/* <td className='border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs p-4'> */}
      {/*   <div className='flex flex-col'> */}
      {/*     {slicedConsultantTypes.map((c, i) => { */}
      {/*       return <div key={i}>{c}</div> */}
      {/*     })} */}
      {/*     {remainingConsultantTypes.length > 0 && !showAllConsultantTypes && ( */}
      {/*       <button onClick={handleShowMore}> */}
      {/*         <i className='fa fa-plus pt-2' /> */}
      {/*       </button> */}
      {/*     )} */}
      {/*     {showAllConsultantTypes && ( */}
      {/*       <> */}
      {/*         {remainingConsultantTypes.map((c, i) => { */}
      {/*           return <div key={i}>{c}</div> */}
      {/*         })} */}
      {/*         <button onClick={handleShowLess}> */}
      {/*           <i className='fa fa-minus pt-2' /> */}
      {/*         </button> */}
      {/*       </> */}
      {/*     )} */}
      {/*     {/\* {business.consultantTypes && business.consultantTypes.map(c => *\/} */}
      {/*     {/\*   <p>{c}</p> *\/} */}
      {/*     {/\* )} *\/} */}
      {/*   </div> */}
      {/* </td> */}
      <td className='border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 text-right'>
        <TableExpertsDropdown business={business} setBusinessModified={setBusinessModified} color={color} />
      </td>
    </tr>
  )
}
