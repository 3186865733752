import React from 'react'
import ReactDOM from 'react-dom'
import { store } from './app/store'
import { Provider } from 'react-redux'
import App from 'App'
import { PersistGate } from 'redux-persist/integration/react'
import { persistStore } from 'redux-persist'

import '@fortawesome/fontawesome-free/css/all.min.css'
import 'assets/styles/tailwind.css'

const persistor = persistStore(store)

ReactDOM.render(
  <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
      <App />
    </PersistGate>
  </Provider>,
  document.getElementById('root')
)
