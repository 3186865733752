import '@reach/dialog/styles.css'
import { useEffect, useState, useCallback } from 'react'
import { getAllBusinessesMap, getAllUnassignedMap } from '../../services'
import { GoogleMap, Marker, useJsApiLoader } from '@react-google-maps/api'

const containerStyle = {
  minWidth: '900px',
  height: '600px'
}

const center = {
  lat: 52.48681447157704,
  lng: -1.8773693070026933
}

const Map = () => {
  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY
  })

  const [businesses, setBusinesses] = useState([])
  const [unassigned, setUnassigned] = useState([])
  useEffect(() => {
    getAllBusinessesMap(setBusinesses)
    getAllUnassignedMap(setUnassigned)
  }, [])

  const [map, setMap] = useState(null)

  const onLoad = useCallback(function callback (map) {
    setMap(map)
  }, [])

  const onUnmount = useCallback(function callback (map) {
    setMap(null)
  }, [])

  const markers = []
  for (const b of businesses) {
    markers.push(<Marker
      position={{
        lat: b.lat,
        lng: b.long
      }}
      title={b.name}
      icon='http:maps.google.com/mapfiles/ms/icons/red.png'
                 />)
  }

  for (const u of unassigned) {
    markers.push(<Marker
      position={{
        lat: u.lat,
        lng: u.long
      }}
      title={u.address}
      icon='http:maps.google.com/mapfiles/ms/icons/blue.png'
                 />)
  }

  return isLoaded
    ? (
      <center>
        <br />
        <br />
        <GoogleMap
          mapContainerStyle={containerStyle}
          center={center}
          zoom={7}
          onUnmount={onUnmount}
          onLoad={onLoad}
        >
          {markers}
        </GoogleMap>
      </center>
      )
    : (
      <div>Hello</div>
      )
}

export default Map
