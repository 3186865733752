import React from 'react'
import { Routes, Route, Navigate } from 'react-router-dom'
// components
import AdminNavbar from 'components/Navbars/AdminNavbar.js'
import Sidebar from 'components/Sidebar/Sidebar.js'
import HeaderStats from 'components/Headers/HeaderStats.js'
import FooterAdmin from 'components/Footers/FooterAdmin.js'
// views
import Dashboard from 'views/admin/Dashboard.js'
import Maps from 'views/admin/Maps.js'
import Settings from 'views/admin/Settings.js'
import Leads from 'views/admin/Leads.js'
import FakeLeads from 'views/admin/FakeLeads.js'
import Experts from 'views/admin/Experts.js'

export default function Admin () {
  const [collapseNav, setCollapseNav] = React.useState(false)

  return (
    <>
      <Sidebar collapseNav={collapseNav} setCollapseNav={setCollapseNav} />
      <div className={`${collapseNav ? '' : 'md:ml-64'} relative bg-blueGray-100 `}>
        {/* <AdminNavbar /> */}
        {/* Header */}
        {/* <HeaderStats /> */}
        <div className='px-4 md:px-10 pt-32 mx-auto w-full -m-24'>
          <Routes>
            {/* <Route path='/admin' element={<Navigate to='/admin/dashboard' />} /> */}
            <Route path='dashboard' exact element={<Dashboard />} />
            <Route path='maps' exact element={<Maps />} />
            <Route path='settings' exact element={<Settings />} />
            <Route path='leads' exact element={<Leads />} />
            <Route path='experts' exact element={<Experts />} />
            <Route path='fake-leads' exact element={<FakeLeads />} />
          </Routes>
          <FooterAdmin />
        </div>
      </div>
    </>
  )
}
