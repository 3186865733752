import React from 'react'
import PropTypes from 'prop-types'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faLocationDot } from '@fortawesome/free-solid-svg-icons'

// components

import TableDropdown from 'components/Dropdowns/TableDropdown.js'

export default function CardTable ({
  color,
  leads,
  setLeads,
  page,
  setPage,
  handleSubmit,
  setRowsPerPage,
  setUnassignedFilter
}) {
  const searchFields = [
    'email',
    'phone',
    'address',
    'message'
  ]

  const rowsPerPageOptions = [
    25,
    50,
    100,
    'all'
  ]

  const projects = JSON.parse(window.localStorage.getItem('projects'))
  const consultants = JSON.parse(window.localStorage.getItem('consultants'))

  function getConsultantTypeName (id) {
    for (const consultant of consultants) {
      if (consultant.id === id) { return consultant.name }
    }
    return 1
  }

  function getProjectTypeName (id) {
    for (const project of projects) {
      if (project.id === id) { return project.name }
    }
    return 1
  }

  return (
    <>
      <div
        className={
          'relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded ' +
          (color === 'light' ? 'bg-white' : 'bg-lightBlue-900 text-white')
        }
      >
        <div className='rounded-t mb-0 px-4 py-3 border-0'>
          <div className='flex flex-wrap items-center'>
            <div className='relative w-full px-4 max-w-full flex-grow flex-1'>
              <h3
                className={
                  'font-semibold text-lg ' +
                  (color === 'light' ? 'text-blueGray-700' : 'text-white')
                }
              >
                Leads ({leads.count || 0} total)
              </h3>
              <form
                className='md:flex hidden flex-row flex-wrap items-center lg:ml-10 mr-3 gap-y-2'
                onSubmit={(e) => handleSubmit(e)}
              >
                <div className='relative w-1/2'>
                  <div className='absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none'>
                    <svg aria-hidden='true' className='w-5 h-5 text-gray-500 dark:text-gray-400' fill='none' stroke='currentColor' viewBox='0 0 24 24' xmlns='http://www.w3.org/2000/svg'><path stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z' /></svg>
                  </div>
                  <input
                    type='search' id='leads-search' className='block w-full p-4 pl-10 text-sm text-gray-900 border border-gray-300 rounded-lg bg-gray-50 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500'
                    placeholder='Search Leads ...'
                  />
                  <button type='submit' className='text-white absolute right-2.5 bottom-2.5 bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-4 py-2 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800'>Search</button>
                </div>
                <select id='leadSearchFields' className='w-1/2 bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500'>
                  <option selected value='name'>name</option>
                  {searchFields.map((s, i) => {
                    return <option key={i} value={s}>{s.replace(/([A-Z])/g, ' $1').trim()}</option>
                  })}
                </select>

                <div className='flex flex-row gap-x-8 align-center '>
                  <div className='flex flex-row gap-x-2 items-center'>
                    <label>Consultant Type:</label>
                    <select id='consultantTypesSelect' className='w-1/2 bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500'>
                      <option selected value=''>All</option>
                      {consultants && consultants?.map((c, i) => {
                        return <option key={i} value={c.id}>{c.name}</option>
                      })}
                    </select>
                  </div>
                  <div className='flex flex-row gap-x-2 items-center'>
                    <label>Project Type:</label>
                    <select id='projectTypesSelect' className='w-1/2 bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500'>
                      <option selected value=''>All</option>
                      {projects.map((p, i) => {
                        return <option key={i} value={p.id}>{p.name}</option>
                      })}
                    </select>
                  </div>
                  <div className='flex items-center mb-4'>
                    <input
                      id='unassignedLeadsCheckbox' type='checkbox' value=''
                      onClick={() => setUnassignedFilter(current => !current)}
                      className='w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600'
                    />
                    <label for='unassignedLeadsCheckbox' className='ml-2 text-sm font-medium text-gray-900 dark:text-gray-300'>See unassigned leads</label>
                  </div>
                </div>
                <div className='flex flex-row items-center'>
                  <label>Show</label>
                  <select
                    id='rowsPageOptions'
                    className='w-24 bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500'
                    onChange={(e) => setRowsPerPage(e.target.value)}
                  >
                    <option selected value={10}>10</option>
                    {rowsPerPageOptions.map((r, i) => {
                      return <option key={i} value={r}>{r}</option>
                    })}
                  </select>
                  <label>fields</label>
                </div>
              </form>
            </div>
          </div>
        </div>
        <div className='block w-full overflow-x-auto'>
          {/* Projects table */}
          <table className='items-center w-full bg-transparent border-collapse'>
            <thead>
              <tr>
                <th
                  className={
                    'px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left ' +
                    (color === 'light'
                      ? 'bg-blueGray-50 text-blueGray-500 border-blueGray-100'
                      : 'bg-lightBlue-800 text-lightBlue-300 border-lightBlue-700')
                  }
                >
                  Date
                </th>
                <th
                  className={
                    'px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 font-semibold text-left ' +
                    (color === 'light'
                      ? 'bg-blueGray-50 text-blueGray-500 border-blueGray-100'
                      : 'bg-lightBlue-800 text-lightBlue-300 border-lightBlue-700')
                  }
                >
                  Client Info
                </th>
                <th
                  className={
                    'px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left ' +
                    (color === 'light'
                      ? 'bg-blueGray-50 text-blueGray-500 border-blueGray-100'
                      : 'bg-lightBlue-800 text-lightBlue-300 border-lightBlue-700')
                  }
                >
                  Lead Info
                </th>
                <th
                  className={
                    'px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left ' +
                    (color === 'light'
                      ? 'bg-blueGray-50 text-blueGray-500 border-blueGray-100'
                      : 'bg-lightBlue-800 text-lightBlue-300 border-lightBlue-700')
                  }
                >
                  Message
                </th>
                <th
                  className={
                    'px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left ' +
                    (color === 'light'
                      ? 'bg-blueGray-50 text-blueGray-500 border-blueGray-100'
                      : 'bg-lightBlue-800 text-lightBlue-300 border-lightBlue-700')
                  }
                />
              </tr>
            </thead>

            <tbody>
              {leads && leads.rows.map((lead, i) =>
                <tr key={lead.name + lead.message + i} className='border-b border-black-100'>
                  <td className='border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4'>
                    <div className='flex items-center'>
                      <span className='mr-2'>
                        {lead.createdAt.split('T')[0].split(',')[0]}
                        <br />
                        {lead.createdAt.split('T')[0].split(',')[1]}
                      </span>
                    </div>
                  </td>
                  <td className='border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs p-4 text-left whitespace-nowrap'>
                    <div>
                      {/* <img */}
                      {/*   src={require('assets/img/bootstrap.jpg').default} */}
                      {/*   className='h-12 w-12 bg-white rounded-full border' */}
                      {/*   alt='...' */}
                      {/* />{' '} */}
                      <div>
                        <i className='fas fa-user-tie' />
                        <span
                          className={
                            'ml-3 font-normal ' +
                              +(color === 'light' ? 'text-blueGray-600' : 'text-white')
                          }
                        >
                          {lead.name}
                        </span>
                      </div>
                      <div>
                        <i className='fas fa-phone' />
                        <span
                          className={
                            'ml-3 font-normal break-all ' +
                              +(color === 'light' ? 'text-blueGray-600' : 'text-white')
                          }
                        >
                          {lead.phone}
                        </span>
                      </div>
                      <div>
                        <i className='fas fa-envelope' />
                        <span
                          className={
                            'ml-3 font-normal break-all ' +
                              +(color === 'light' ? 'text-blueGray-600' : 'text-white')
                          }
                        >
                          {lead.email}
                        </span>
                      </div>
                    </div>
                  </td>
                  <td className='border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs p-4'>
                    <div className='flex flex-col'>
                      <div>
                        {/* <span */}
                        {/*   className={ */}
                        {/*     'ml-3 font-bold break-all ' + */}
                        {/*       +(color === 'light' ? 'text-blueGray-600' : 'text-white') */}
                        {/*   } */}
                        {/* > */}
                        {/*   Consultant: */}
                        {/* </span> */}
                        <span
                          className={
                            'ml-3 font-normal ' +
                              +(color === 'light' ? 'text-blueGray-600' : 'text-white')
                          }
                        >
                          {consultants && getConsultantTypeName(lead.consultantTypeId)}
                        </span>
                      </div>
                      <div>
                        {/* <span */}
                        {/*   className={ */}
                        {/*     'ml-3 font-bold ' + */}
                        {/*       +(color === 'light' ? 'text-blueGray-600' : 'text-white') */}
                        {/*   } */}
                        {/* > */}
                        {/*   Project: */}
                        {/* </span> */}
                        <span
                          className={
                            'ml-3 font-normal ' +
                              +(color === 'light' ? 'text-blueGray-600' : 'text-white')
                          }
                        >
                          {projects && getProjectTypeName(lead.projectTypeId)}
                        </span>
                      </div>
                      <div className='mt-2'>
                        <FontAwesomeIcon
                          icon={faLocationDot} style={{ width: '12px', marginLeft: '.75rem' }}
                        />
                        <span
                          className={
                            'ml-1 font-normal ' +
                              +(color === 'light' ? 'text-blueGray-600' : 'text-white')
                          }
                        >
                          {lead.address}
                        </span>
                      </div>
                      <div />
                    </div>
                  </td>
                  <td className='border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs p-4'>
                    {lead.message}
                  </td>
                  <td className='border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 text-right'>
                    <TableDropdown lead={lead} />
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      </div>
    </>
  )
}

CardTable.defaultProps = {
  color: 'light'
}

CardTable.propTypes = {
  color: PropTypes.oneOf(['light', 'dark'])
}
