import React from 'react'
import { createPopper } from '@popperjs/core'
import BusinessInfoModal from '../Modals/BusinessInfoModal'
import BusinessEmailsModal from '../Modals/BusinessEmailsModal'
import BusinessLeadsModal from '../Modals/BusinessLeadsModal'
import BusinessAreasModal from '../Modals/BusinessAreasModal'
import BusinessConsultantsModal from '../Modals/BusinessConsultantsModal'
import { resendEmailVerification } from 'services'

const TableExpertsDropdown = ({ business, setBusinessModified }) => {
  // dropdown props
  const [dropdownPopoverShow, setDropdownPopoverShow] = React.useState(false)
  const [businessLeadsModalShow, setBusinessLeadsModalShow] = React.useState(false)
  const [businessEmailsModalShow, setBusinessEmailsModalShow] = React.useState(false)
  const [businessAreasModalShow, setBusinessAreasModalShow] = React.useState(false)
  const [businessInfoModalShow, setBusinessInfoModalShow] = React.useState(false)
  const [businessConsultantsModalShow, setBusinessConsultantsModalShow] = React.useState(false)

  const btnDropdownRef = React.createRef()
  const popoverDropdownRef = React.createRef()

  const openDropdownPopover = () => {
    createPopper(btnDropdownRef.current, popoverDropdownRef.current, {
      placement: 'left-start'
    })
    setDropdownPopoverShow(true)
  }

  const closeDropdownPopover = () => {
    setDropdownPopoverShow(false)
  }

  return (
    <>
      <a
        className='text-blueGray-500 py-1 px-3'
        href='#pablo'
        ref={btnDropdownRef}
        onClick={(e) => {
          e.preventDefault()
          dropdownPopoverShow ? closeDropdownPopover() : openDropdownPopover()
        }}
      >
        <i className='fas fa-ellipsis-v' />
      </a>
      <div
        ref={popoverDropdownRef}
        className={
          (dropdownPopoverShow ? 'block ' : 'hidden ') +
          'bg-white text-base z-50 float-left py-2 list-none text-left rounded shadow-lg min-w-48'
        }
      >
        <a
          href='#pablo'
          className='text-sm py-2 px-4 hover:text-white hover:bg-blue-300 font-normal block w-full whitespace-nowrap bg-transparent text-blueGray-700'
          onClick={(e) => {
            e.preventDefault()
            setBusinessInfoModalShow(true)
            setDropdownPopoverShow(false)
          }}
        >
          Business Info
        </a>
        <a
          href='#pablo'
          className='text-sm py-2 px-4 hover:text-white hover:bg-blue-300 font-normal block w-full whitespace-nowrap bg-transparent text-blueGray-700'
          onClick={(e) => {
            e.preventDefault()
            setBusinessEmailsModalShow(true)
            setDropdownPopoverShow(false)
          }}
        >
          View business emails
        </a>
        <a
          href='#pablo'
          className='text-sm hover:bg-blue-300 hover:text-white py-2 px-4 font-normal block w-full whitespace-nowrap bg-transparent text-blueGray-700'
          onClick={(e) => {
            e.preventDefault()
            setBusinessLeadsModalShow(true)
            setDropdownPopoverShow(false)
          }}
        >
          View business assigned leads
        </a>
        <a
          href='#pablo'
          className='text-sm hover:bg-blue-300 hover:text-white py-2 px-4 font-normal block w-full whitespace-nowrap bg-transparent text-blueGray-700'
          onClick={(e) => {
            e.preventDefault()
            setBusinessConsultantsModalShow(true)
            setDropdownPopoverShow(false)
          }}
        >
          View business consultants
        </a>
        <a
          href='#pablo'
          className='text-sm hover:bg-blue-300 hover:text-white py-2 px-4 font-normal block w-full whitespace-nowrap bg-transparent text-blueGray-700'
          onClick={(e) => {
            e.preventDefault()
            setBusinessAreasModalShow(true)
            setDropdownPopoverShow(false)
          }}
        >
          View business areas
        </a>
        <a
          href='#pablo'
          className='text-sm hover:bg-blue-300 hover:text-white py-2 px-4 font-normal block w-full whitespace-nowrap bg-transparent text-blueGray-700'
          onClick={async (e) => {
            e.preventDefault()
            setDropdownPopoverShow(false)
            if (window.confirm('Are you sure you want to resend email verification?')) {
              await resendEmailVerification(business.id)
            }
          }}
        >
          Resend email verification...
        </a>
      </div>
      <BusinessInfoModal
        state={businessInfoModalShow}
        setState={setBusinessInfoModalShow}
        business={business} setBusinessModified={setBusinessModified}
      />
      <BusinessEmailsModal
        state={businessEmailsModalShow}
        setState={setBusinessEmailsModalShow}
        business={business}
      />
      <BusinessLeadsModal
        state={businessLeadsModalShow}
        setState={setBusinessLeadsModalShow}
        business={business}
      />
      <BusinessAreasModal
        state={businessAreasModalShow}
        setState={setBusinessAreasModalShow}
        business={business}
      />
      <BusinessConsultantsModal
        state={businessConsultantsModalShow}
        setState={setBusinessConsultantsModalShow}
        business={business}
      />
    </>
  )
}

export default TableExpertsDropdown
