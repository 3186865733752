import { useEffect, useState } from 'react'
import { deleteExpert, updateExpert } from '../../services'
import Modal from './Modal'

const BusinessInfoModal = ({ setState, state, business, setBusinessModified }) => {
  const [newUser, setNewUser] = useState({
    id: business.id,
    address: business.address,
    email: business.email,
    name: business.name,
    representativeName: business.representativeName,
    representativeLastName: business.representativeLastName,
    phone: business.phone,
    userVerified: business.userVerified,
    notes: business.notes
  })

  const handleChangeUser = (e) => {
    setNewUser({ ...newUser, [e.target.name]: e.target.value })
  }
  const updateCurrentExpert = async () => {
    const res = window.confirm('Are you sure you want to update this Expert?')
    if (res === true) {
      try {
        await updateExpert(newUser)
        setBusinessModified(true)
        setState(false)
      } catch (err) {
        console.log(err)
      }
    }
  }
  const removeCurrentExpert = async () => {
    const res = window.confirm('Are you sure you want to delete this Expert?')
    if (res === true) {
      try {
        await deleteExpert(business.id)
        setBusinessModified(true)
        setState(false)
      } catch (err) {
        console.log(err)
      }
    }
  }

  return (
    <Modal
      state={state} setState={setState}
      customStyle={{
        borderTopLeftRadius: '0.25rem',
        borderTopRightRadius: '0.25rem'
      }}
    >
      <div className='flex flex-col'>
        <h1 className='font-bold text-xl mb-4'>{business.name} Info: </h1>

        <div className='flex flex-col gap-x-2'>
          <label> Company Name: </label>
          <input type='text' placeholder={newUser.name} name='name' onChange={(e) => handleChangeUser(e)} />
          <label className='pt-2'> Representative Name: </label>
          <input type='text' placeholder={newUser.representativeName} name='representativeName' onChange={(e) => handleChangeUser(e)} />
          <label className='pt-2'> Representative LastName: </label>
          <input type='text' placeholder={newUser.representativeLastName} name='representativeLastName' onChange={(e) => handleChangeUser(e)} />
          <label className='pt-2'> Address: </label>
          <input type='text' placeholder={newUser.address} name='address' onChange={(e) => handleChangeUser(e)} />
          <label className='pt-2'> Email: </label>
          <input type='text' placeholder={newUser.email} name='email' onChange={(e) => handleChangeUser(e)} />
          <label className='pt-2'> Phone: </label>
          <input type='text' placeholder={newUser.phone} name='phone' onChange={(e) => handleChangeUser(e)} />
          <div classname='flex flex-col'>
            <div>
              <label className='mr-4'>
                <input
                  type='radio' name='userVerified' value={1} className='mr-2'
                  checked={parseInt(newUser.userVerified) === 1} onChange={(e) => handleChangeUser(e)}
                />
                Active
              </label>
              <label className='mr-4'>
                <input
                  type='radio' name='userVerified' value={2} className='mr-2'
                  checked={parseInt(newUser.userVerified) === 2} onChange={(e) => handleChangeUser(e)}
                />
                Suspended
              </label>
              <label className='mr-4'>
                <input
                  type='radio' name='userVerified' value={3} className='mr-2'
                  checked={parseInt(newUser.userVerified) === 3} onChange={(e) => handleChangeUser(e)}
                />
                Deleted
              </label>
            </div>
            <div>
              <label className='pt-2'> Notes: </label>
              <textarea className='w-full h-40' name='notes' placeholder={newUser.notes} value={newUser.notes} onChange={(e) => handleChangeUser(e)} />
            </div>
          </div>
          <div>
            <button
              className='mt-4 ml-2 bg-green-300 rounded-t py-0.5 px-1'
              onClick={async () => {
                await updateCurrentExpert()
                setState(false)
              }}
            >
              Save
            </button>
            <button
              className='ml-2 bg-red-300 rounded-t py-0.5 px-1'
              onClick={async () => {
                await removeCurrentExpert()
                setState(false)
              }}
            >
              Delete (permanent)
            </button>
          </div>
        </div>
      </div>
    </Modal>
  )
}

export default BusinessInfoModal
