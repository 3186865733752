import { useEffect, useState } from 'react'
import { getBusinessConsultants, selectBusinessConsultants, getConsultants } from '../../services'
import Modal from './Modal'

const BusinessConsultantsModal = ({ setState, state, business }) => {
  const [businessConsultants, setBusinessConsultants] = useState(false)
  const [selectedConsultants, setSelectedConsultants] = useState(false)
  const consultants = JSON.parse(window.localStorage.getItem('consultants'))

  const handleChange = (e) => {
    const consultantId = parseInt(e.target.value)

    e.target.checked
      ? setBusinessConsultants([...businessConsultants, { id: consultantId }])
      : setBusinessConsultants(businessConsultants.filter((b) => b.id !== consultantId))
  }

  useEffect(() => {
    state && getBusinessConsultants(setBusinessConsultants, business.id)
  }, [state])

  return (
    <Modal
      state={state} setState={setState}
      customStyle={{
        borderTopLeftRadius: '0.25rem',
        borderTopRightRadius: '0.25rem'
      }}
    >
      <div className='flex flex-col'>
        <h1 className='font-bold text-xl mb-4'>Consultants: </h1>
        {consultants && consultants.map((e, i) => {
          return (
            <>
              <label className='py-1'>
                <input
                  type='checkbox' className='mr-2'
                  checked={businessConsultants && businessConsultants.some(b => b.id === e.id)}
                  onChange={(e) => handleChange(e)}
                  name='' value={e.id}
                />
                {e.name}
              </label>
              <hr />
            </>
          )
        })}
        <button
          className='mt-4 ml-2 bg-green-300 rounded-t py-0.5 px-1'
          onClick={async () => {
            await selectBusinessConsultants(
              businessConsultants.map(c => { return { consultantTypeId: c.id } }),
              business.id
            )
            setState(false)
          }}
        >
          Save
        </button>
      </div>
    </Modal>
  )
}

export default BusinessConsultantsModal
