import React, { useState, useEffect } from 'react'
import { Link, Navigate, useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { userLogin } from 'app/actions/loginActions'

export default function Login () {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const userToken = useSelector((state) => state.login.userToken)

  const [formState, setFormState] = useState({
    email: '',
    password: ''
  })

  // useEffect(() => {
  //   if (userToken) {
  //     navigate('/admin/dashboard')
  //   }
  // }, [])

  // Handle email change
  const handleEmailChange = e => {
    setFormState({
      ...formState,
      email: e.target.value
    })
  }

  // Handle password change
  const handlePasswordChange = e => {
    setFormState({
      ...formState,
      password: e.target.value
    })
  }

  const signInx = async (e) => {
    e.preventDefault()
    const a = await dispatch(userLogin(formState))
    if (!Object.hasOwn(a.payload, 'error')) {
      navigate('/admin/dashboard')
    } else {
      alert('Incorrect email or password')
    }
  }

  return (
    <>
      <div className='container mx-auto px-4 h-full'>
        <div className='flex content-center items-center justify-center h-full'>
          <div className='w-full lg:w-4/12 px-4'>
            <div className='relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg bg-blueGray-200 border-0'>
              <div className='flex-auto px-4 lg:px-10 py-10 pt-10'>
                <form
                  onSubmit={signInx}
                >
                  <div className='relative w-full mb-3'>
                    <label
                      className='block uppercase text-blueGray-600 text-xs font-bold mb-2'
                      htmlFor='grid-password'
                    >
                      Email
                    </label>
                    <input
                      name='email'
                      type='email'
                      className='border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150'
                      placeholder='Email'
                      onChange={handleEmailChange}
                    />
                  </div>

                  <div className='relative w-full mb-3'>
                    <label
                      className='block uppercase text-blueGray-600 text-xs font-bold mb-2'
                      htmlFor='grid-password'
                    >
                      Password
                    </label>
                    <input
                      name='password'
                      type='password'
                      className='border-0 px-3 py-3 placeholder-blueGray-300 text-blueGray-600 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full ease-linear transition-all duration-150'
                      placeholder='Password'
                      onChange={handlePasswordChange}
                    />
                  </div>

                  <div className='text-center mt-6'>
                    <button
                      className='bg-blueGray-800 text-white active:bg-blueGray-600 text-sm font-bold uppercase px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 w-full ease-linear transition-all duration-150'
                      type='submit'
                    >
                      Sign In
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
