import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faXmark } from '@fortawesome/free-solid-svg-icons'
import LeadDataModal from '../Modals/LeadDataModal'

// components
import TableFakeLeadDropdown from 'components/Dropdowns/TableFakeLeadDropdown.js'

export default function CardTableFakeLeads ({
  color,
  leads,
  setLeads,
  page,
  setPage,
  handleSubmit,
  setLeadModified,
  setRowsPerPage
}) {
  const [leadInfoModalShow, setLeadInfoModalShow] = useState(false)

  const rowsPerPageOptions = [
    25,
    50,
    100
  ]

  const reasonArray = [
    'Email does not exist',
    'Phone number is fake',
    'Lead not related to construction',
    'Lead not related to my filters',
    'Other'
  ]

  return (
    <>
      <div
        className={
          'relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded ' +
          (color === 'light' ? 'bg-white' : 'bg-lightBlue-900 text-white')
        }
      >
        <div className='rounded-t mb-0 px-4 py-3 border-0'>
          <div className='flex flex-wrap items-center'>
            <div className='relative w-full px-4 max-w-full flex-grow flex-1'>
              <h3
                className={
                  'font-semibold text-lg ' +
                  (color === 'light' ? 'text-blueGray-700' : 'text-white')
                }
              >
                Fake Leads ({leads.count || 0} total)
              </h3>
            </div>
          </div>
        </div>
        <div className='block w-full overflow-x-auto'>
          {/* Projects table */}
          <table className='items-center w-full bg-transparent border-collapse'>
            <thead>
              <tr>
                <th
                  className={
                    'px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left ' +
                    (color === 'light'
                      ? 'bg-blueGray-50 text-blueGray-500 border-blueGray-100'
                      : 'bg-lightBlue-800 text-lightBlue-300 border-lightBlue-700')
                  }
                >
                  Date
                </th>
                <th
                  className={
                    'px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 font-semibold text-left ' +
                    (color === 'light'
                      ? 'bg-blueGray-50 text-blueGray-500 border-blueGray-100'
                      : 'bg-lightBlue-800 text-lightBlue-300 border-lightBlue-700')
                  }
                >
                  Free
                </th>
                <th
                  className={
                    'px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 font-semibold text-left ' +
                    (color === 'light'
                      ? 'bg-blueGray-50 text-blueGray-500 border-blueGray-100'
                      : 'bg-lightBlue-800 text-lightBlue-300 border-lightBlue-700')
                  }
                >
                  Business
                </th>
                <th
                  className={
                    'px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left ' +
                    (color === 'light'
                      ? 'bg-blueGray-50 text-blueGray-500 border-blueGray-100'
                      : 'bg-lightBlue-800 text-lightBlue-300 border-lightBlue-700')
                  }
                >
                  Reason
                </th>
                <th
                  className={
                    'px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left ' +
                    (color === 'light'
                      ? 'bg-blueGray-50 text-blueGray-500 border-blueGray-100'
                      : 'bg-lightBlue-800 text-lightBlue-300 border-lightBlue-700')
                  }
                >
                  Lead Info
                </th>
                <th
                  className={
                    'px-6 align-middle border border-solid py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left ' +
                    (color === 'light'
                      ? 'bg-blueGray-50 text-blueGray-500 border-blueGray-100'
                      : 'bg-lightBlue-800 text-lightBlue-300 border-lightBlue-700')
                  }
                />
              </tr>
            </thead>

            <tbody>
              {leads && leads.rows.map((lead, i) =>
                <RowFakeLeads lead={lead} key={i} setLeadModified={setLeadModified} />
              )}
            </tbody>
          </table>
        </div>
      </div>
    </>
  )
}

const RowFakeLeads = ({ lead, key, setLeadModified }) => {
  const [leadInfoModalShow, setLeadInfoModalShow] = useState(false)

  const reasonArray = [
    'Email does not exist',
    'Phone number is fake',
    'Lead not related to construction',
    'Lead not related to my filters',
    'Other'
  ]

  return (
    <tr key={key} className='border-b border-black-100'>
      <td className='border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4'>
        <div className='flex items-center'>
          <span className='mr-2'>
            {lead.createdAt.split('T')[0].split(',')[0]}
            <br />
            {lead.createdAt.split('T')[0].split(',')[1]}
          </span>
        </div>
      </td>
      <td className='border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs p-4 text-left whitespace-nowrap'>
        {lead.free
          ? <i className='fas fa-check mr-2 text-lg text-green-500' />
          : <FontAwesomeIcon icon={faXmark} className='mr-2 text-lg text-red-500' />}
      </td>
      <td className='border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs p-4 text-left whitespace-nowrap'>
        {lead['business.name']}
      </td>
      <td className='border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4'>
        {reasonArray[lead.fake - 1]}
      </td>
      <td className='border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4'>
        <button
          className='py-2 px-4 shadow-md no-underline rounded-full bg-blue-700 text-white font-semibold text-sm border-blue btn-primary focus:outline-none active:shadow-none mr-2'
          onClick={() => setLeadInfoModalShow(true)}
        >
          View lead info...
        </button>
      </td>
      <td className='border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4 text-right'>
        <TableFakeLeadDropdown lead={lead} setLeadModified={setLeadModified} />
      </td>
      <LeadDataModal
        state={leadInfoModalShow}
        setState={setLeadInfoModalShow}
        leadId={lead.leadId}
      />
    </tr>
  )
}

CardTableFakeLeads.defaultProps = {
  color: 'light'
}

CardTableFakeLeads.propTypes = {
  color: PropTypes.oneOf(['light', 'dark'])
}
