import React from 'react'
import { createPopper } from '@popperjs/core'
import { deleteLead, markLeadAsGenuine } from '../../services'

const TableDropdown = ({ lead, setLeadModified }) => {
  // dropdown props
  const [dropdownPopoverShow, setDropdownPopoverShow] = React.useState(false)

  const btnDropdownRef = React.createRef()
  const popoverDropdownRef = React.createRef()

  const openDropdownPopover = () => {
    createPopper(btnDropdownRef.current, popoverDropdownRef.current, {
      placement: 'left-start'
    })
    setDropdownPopoverShow(true)
  }
  const closeDropdownPopover = () => {
    setDropdownPopoverShow(false)
  }

  return (
    <>
      <a
        className='text-blueGray-500 py-1 px-3'
        href='#pablo'
        ref={btnDropdownRef}
        onClick={(e) => {
          e.preventDefault()
          dropdownPopoverShow ? closeDropdownPopover() : openDropdownPopover()
        }}
      >
        <i className='fas fa-ellipsis-v' />
      </a>
      <div
        ref={popoverDropdownRef}
        className={
          (dropdownPopoverShow ? 'block ' : 'hidden ') +
          'bg-white text-base z-50 float-left py-2 list-none text-left rounded shadow-lg min-w-48'
        }
      >
        <a
          href='#pablo'
          className='text-sm py-2 px-4 hover:text-white hover:bg-red-500 font-normal block w-full whitespace-nowrap bg-transparent text-blueGray-700'
          onClick={async (e) => {
            e.preventDefault()
            setDropdownPopoverShow(false)
            if (window.confirm('Are you sure you want to delete this lead?')) {
              await deleteLead(lead.leadId)
              setLeadModified(true)
            }
          }}
        >
          Delete lead...
        </a>
        <a
          href='#pablo'
          className='text-sm py-2 px-4 hover:text-white hover:bg-blue-300 font-normal block w-full whitespace-nowrap bg-transparent text-blueGray-700'
          onClick={async (e) => {
            e.preventDefault()
            setDropdownPopoverShow(false)
            if (window.confirm('Are you sure you want to mark this lead as genuine?')) {
              await markLeadAsGenuine(lead.leadId)
              setLeadModified(true)
            }
          }}
        >
          Mark as genuine...
        </a>
      </div>
    </>
  )
}

export default TableDropdown
