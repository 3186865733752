import { useEffect, useState } from 'react'
import { toast } from 'react-toastify'
import { sendClaimEmailAdmin, getAllBusinesses, getBusinessesAssignedTo, unassign } from '../../services'
import Modal from './Modal'

const LeadClaimEmailModal = ({ setState, state, lead }) => {
  const [leadBusinesses, setLeadBusinesses] = useState([])
  const [allBusinesses, setAllBusinesses] = useState([])
  const [selectedOptions, setSelectedOptions] = useState([])

  useEffect(() => {
    if (state) {
      getBusinessesAssignedTo(setLeadBusinesses, lead.id)
      getAllBusinesses(setAllBusinesses, lead.id)
    }
  }, [state])

  const handleChange = (e) => {
    const selected = Array.from(e.target.selectedOptions,
      (option) => option.value)
    setSelectedOptions(selected)
  }

  return (
    <Modal
      state={state} setState={setState}
      customStyle={{
        borderTopLeftRadius: '0.25rem',
        borderTopRightRadius: '0.25rem'
      }}
    >
      <div className='flex flex-column gap-x-2'>
        <div className='w-1/2'>
          <h1 className='font-bold text-xl mb-4'>Send claim lead email to:</h1>
          <label
            for='allBusinesses'
            className='block mb-2 text-sm font-medium text-gray-900 dark:text-white'
          >
            Select an option
          </label>
          <select multiple id='allBusinesses' onChange={handleChange} className='bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500'>
            {allBusinesses &&
             allBusinesses.map((el) =>
               <option key={el.id} value={el.id}>{el.name}</option>
             )}
          </select>
          <button
            onClick={async () => {
              await sendClaimEmailAdmin(lead.id, selectedOptions)
              /* getBusinessesAssignedTo(setLeadBusinesses, lead.id) */
              toast.success('Emails resent succesfully')
            }}
            className='ml-2 bg-green-300 rounded-t py-0.5 px-1'
          >
            Send emails
          </button>
        </div>
        <div className='w-1/2'>
          <h1 className='font-bold text-xl mb-4'>Assigned to:</h1>
          {leadBusinesses &&
           leadBusinesses.map((el) =>
             <p key={el.id}>
               {el.name}
               <button
                 onClick={async () => {
                   await unassign(lead.id, el.id)
                   await getBusinessesAssignedTo(setLeadBusinesses, lead.id)
                 }}
                 className={`ml-2 ${el.subscription ? 'bg-emerald-400' : 'bg-red-300'} rounded-t py-0.5 px-1`}
               >
                 x
               </button>
             </p>)}
        </div>
      </div>
    </Modal>
  )
}

export default LeadClaimEmailModal
