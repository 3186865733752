import { DialogOverlay, DialogContent } from '@reach/dialog'
import '@reach/dialog/styles.css'

export default function Modal ({
  children,
  state,
  setState,
  ariaLabel,
  scrollUnlock = false,
  customStyle
}) {
  const handleDismissal = () => {
    setState(false)
  }

  return (
    <DialogOverlay
      dangerouslyBypassScrollLock={scrollUnlock}
      isOpen={state}
      onDismiss={handleDismissal}
      allowPinchZoom
    >
      <DialogContent
        aria-label={ariaLabel}
        style={{
          display: 'flex !important',
          position: 'relative',
          flexDirection: 'column !important',
          padding: '3rem !important',
          borderRadius: '1.5rem !important',
          maxWidth: '1000px',
          zIndex: 999,
          backgroundColor: 'white',
          width: 'fit-content !important',
          height: 'fit-content !important',
          ...customStyle
        }}
      >
        <button
          onClick={() => {
            handleDismissal()
          }}
          className='ml-2 bg-red-300 rounded-full p-1 absolute right-2 top-0'
        >
          X
        </button>
        {children}
      </DialogContent>
    </DialogOverlay>
  )
}
