import { useEffect, useState } from 'react'
import { sendFishyEmails } from '../../services'
import Modal from './Modal'

const LeadFishyModal = ({ setState, state, leadId, leadName }) => {
  const [fishyContactsArray, setFishyContactsArray] = useState([])
  const [newContact, setNewContact] = useState({})

  const handleChangeContact = (e) => {
    setNewContact({ ...newContact, [e.target.name]: e.target.value })
  }
  const handleAddContact = () => {
    setFishyContactsArray(prev => [...prev, newContact])
    setNewContact({ name: '', url: '', email: '' })
  }
  const handleRemoveContact = (contact) => {
    setFishyContactsArray(prev => prev.filter(c => c.url !== contact.url))
  }

  return (
    <Modal
      state={state} setState={setState}
      customStyle={{
        borderTopLeftRadius: '0.25rem',
        borderTopRightRadius: '0.25rem'
      }}
    >
      <div className='flex flex-col'>
        <h1 className='font-bold text-xl mb-4'>Send Fishy Emails for {leadName}: </h1>
        <div className='flex gap-x-8'>
          <div className='flex flex-col gap-x-2'>
            <label> Company Name: </label>
            <input type='text' name='name' value={newContact.name} onChange={(e) => handleChangeContact(e)} />
            <label className='pt-2'>Url</label>
            <input type='text' name='url' value={newContact.url} onChange={(e) => handleChangeContact(e)} />
            <label className='pt-2'>Email</label>
            <input type='text' name='email' value={newContact.email} onChange={(e) => handleChangeContact(e)} />
            <button
              className='mt-4 ml-2 bg-green-300 rounded-t py-0.5 px-1'
              onClick={() => {
                if (!newContact.name || !newContact.email || !newContact.url) { return }
                handleAddContact()
              }}
            >
              Save
            </button>
          </div>
          <div className='flex flex-col gap-y-2'>
            {fishyContactsArray && fishyContactsArray?.map(c => {
              return (
                <div className='flex'>
                  <div className='flex flex-col'>
                    <div>
                      <strong>Name: </strong> {c.name}
                    </div>
                    <div>
                      <strong>Email: </strong> {c.email}
                    </div>
                    <div>
                      <strong>Url: </strong> {c.url}
                    </div>
                  </div>
                  <button
                    className='mt-4 ml-2 bg-red-300 rounded-t py-0.5 px-1'
                    onClick={() => handleRemoveContact(c)}
                  >
                    x
                  </button>
                </div>
              )
            })}
          </div>
        </div>

        {fishyContactsArray?.length > 0 &&
          <button
            className='mt-4 ml-2 bg-green-300 rounded-t py-0.5 px-1'
            onClick={() => {
              if (window.confirm('Are you sure you want to send these emails?')) { sendFishyEmails(fishyContactsArray, leadId) }
            }}
          >
            Send Emails
          </button>}
      </div>
    </Modal>
  )
}

export default LeadFishyModal
